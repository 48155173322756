<template>
  <el-row :gutter="10" class="h100">
    <el-col :span="14" class="h100">
      <common-layout>
        <template #header>
          <el-form inline>
            <el-form-item label="环境">
              <el-select v-model="env" style="width: 100%">
                <el-option v-for="item in envList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="searchVal" placeholder="请输入" clearable />
            </el-form-item>
            <el-form-item label="租户">
              <!-- <el-input v-model="tenantCode" placeholder="请输入" clearable /> -->
              <el-select v-model="tenantCode" filterable remote :remote-method="remoteMethod" :loading="loadingTentant">
                <el-option v-for="item in tenantList" :key="item.tenantCode" :label="item.tenantCode" :value="item.tenantCode"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
              <el-button type="primary" plain @click="handleAdd">新增</el-button>
              <el-button type="primary" plain @click="showNameServer = true">nameServer</el-button><el-button type="primary" icon="el-icon-refresh" plain @click="groupView = !groupView">视图</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template>
          <el-table v-if="!groupView" v-loading="loading" :data="tableData" height="100%" border>
            <el-table-column prop="clusterName" label="集群名" min-width="150" align="center" />
            <el-table-column prop="regionId" label="集群所属区域" min-width="150" align="center" />
            <el-table-column prop="type" label="集群类型" min-width="120" align="center">
              <template slot-scope="scope">
                <span :class="scope.row.type === 'normal' ? 'success' : 'warning'">
                  {{ scope.row.type === 'normal' ? '正常消息' : '消息轨迹' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="nameServerList" label="nameServerList" width="150" align="center">
              <template slot-scope="scope">
                <div class="text-theme" v-for="(item, index) in scope.row.nameServerList" :key="index">{{ item }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="brokers" label="brokers" width="150" align="center">
              <template slot-scope="scope">
                <div class="text-theme" v-for="(item, index) in scope.row.brokers" :key="index">{{ item }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="tenantCode" label="租户" min-width="120" align="center" />
            <el-table-column prop="createByUser" label="创建账号" min-width="120" align="center" />
            <el-table-column prop="createTime" label="创建时间" min-width="180" align="center" />
            <el-table-column prop="updateByUser" label="修改账号" min-width="120" align="center" />
            <el-table-column prop="updateTime" label="修改时间" min-width="180" align="center" />
            <el-table-column label="操作" width="120" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleBind(scope.row)">绑定</el-button>
                <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <template v-else>
            <org-tree :data="item" v-for="(item, index) in orgList" :key="index" :props="{ label: 'name', children: 'children' }" @on-node-click="nodeClick" :render-content="renderContent" :horizontal="true"></org-tree>
          </template>
        </template>
        <template #footer>
          <el-pagination v-if="!groupView" class="mt10" @size-change="changePageSize" @current-change="handleChangePage" :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
        </template>
      </common-layout>
    </el-col>
    <el-col :span="10" class="h100 overflow-auto">
      <bind-modal v-if="handleType === 'bind'" ref="bindDialog" @close="getList" />
      <add-modal v-else ref="dialog" :type="handleType" :detail="curItem" @close="getList" />
    </el-col>
    <name-server :show.sync="showNameServer" />
  </el-row>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/devops/cluster'
import accountApi from '@/api/operate/account'
import addModal from './addModal'
import bindModal from './bindModal.vue'
import nameServer from './nameServer/index'
import orgTree from '@/components/orgTree'

export default {
  data() {
    return {
      handleType: 'add',
      tableData: [],
      searchVal: '',
      total: 0,
      page: 1,
      size: 20,
      loading: false,
      env: 'dev',
      curItem: null,
      showNameServer: false,
      groupView: false,
      orgList: [],
      tenantCode: '',
      tenantList: [],
      loadingTentant: false,
    }
  },
  computed: { ...mapState(['envList']) },
  components: { addModal, bindModal, nameServer, orgTree },
  created() {
    this.getList()
    this.getGroupByNameServer()
    this.getTenantList()
  },
  methods: {
    remoteMethod(val) {
      this.getTenantList(val)
    },
    getTenantList(tenantCode) {
      const params = {
        current: 1,
        size: 20,
        tenantCode: tenantCode || this.tenantCode || undefined,
      }
      this.loadingTentant = true
      accountApi
        .query(params)
        .then(({ data }) => {
          this.tenantList = data.records
        })
        .finally(() => {
          this.loadingTentant = false
        })
    },
    handleAdd() {
      this.handleType = 'add'
      this.curItem = null
    },
    handleEdit(row) {
      this.handleType = 'edit'
      this.curItem = row
    },
    handleBind(row) {
      this.handleType = 'bind'
      this.curItem = row
      this.$nextTick(() => {
        this.$refs['bindDialog'].open(row)
      })
    },
    handleDelete(row) {
      this.$confirm('确认删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.loading = true
        const { id } = row
        api
          .remove({ id })
          .then(() => {
            this.$message.success('操作成功')
            this.getList()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getList()
    },
    changePageSize(size) {
      this.size = size
      this.getList()
    },
    getList() {
      let params = {
        pageSize: this.size,
        pageNum: this.page,
        env: this.env,
        clusterName: this.searchVal || '',
        tenantCode: this.tenantCode,
      }
      this.loading = true
      api
        .getList(params)
        .then(({ data }) => {
          const { content, total } = data
          this.tableData = content || []
          this.total = total
        })
        .finally(() => {
          this.loading = false
          this.handleType = 'add'
          this.curItem = null
        })
    },

    getGroupByNameServer() {
      api.getGroupByNameServer().then(({ data }) => {
        for (let i in data) {
          const children = data[i]
          children.forEach((j) => {
            j.name = j.clusterName
          })
          this.orgList.push({
            name: i,
            children,
          })
        }
      })
    },
    nodeClick(e, data) {
      this.handleType = 'detail'
      this.$nextTick(() => {
        this.curItem = data
      })
    },
    renderContent(h, data) {
      return <div class='tree-node-panel'>{data.name}</div>
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.panel-header {
  display: flex;
  justify-content: space-between;
}
</style>
<style>
.primary {
  color: #409eff !important;
}
</style>
