<template>
  <div class="h-full">
    <el-card shadow="never" class="right-form-card">
      <div slot="header">绑定集群</div>
      <el-form :model="form" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="未分配列表" prop="instanceId" required>
          <el-select v-model="form.instanceId" style="width: 100%">
            <el-option v-for="item in rocketMqList" :key="item.id" :label="item.instanceName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="loading" :loading="loading" type="primary" @click="submitForm">保存</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import api from '@/api/devops/cluster'

export default {
  data() {
    return {
      form: {
        instanceId: '',
      },
      detail: null,
      rules: {
        instanceId: [{ required: true, message: '请选择未分配实例', trigger: 'change' }],
      },
      rocketMqList: [],
      loading: false,
    }
  },
  methods: {
    open(row) {
      this.$nextTick(() => {
        this.detail = row
        this.getUnBindList()
        this.reset()
      })
    },
    getUnBindList() {
      const params = {
        tenantCode: this.detail.tenantCode,
        env: this.detail.env,
      }
      api.getUnBindList(params).then(({ data }) => {
        this.rocketMqList = data
      })
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            clusterId: this.detail.id,
            env: this.detail.env,
          }
          this.loading = true
          api
            .bindCluster(params)
            .then(() => {
              this.$message.success('操作成功')
              this.$emit('close')
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    reset() {
      this.$refs['ruleForm'].resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.panel {
  .el-input-number,
  .el-select,
  .el-input {
    width: 100%;
  }
  .list-item {
    /deep/ .el-form-item__content {
      display: flex;
      .el-button {
        margin-left: 10px;
      }
    }
  }
  .mb-150 {
    margin-bottom: 150px !important;
  }
}
</style>
