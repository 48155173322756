<template>
  <div>
    <el-dialog title="nameServer" width="1000px" :visible="show" @close="close" :close-on-click-modal="false">
      <el-form inline>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" plain @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" v-loading="tableLoading" border height="400px">
        <el-table-column prop="nameServerName" label="名称" align="center" width="120px"></el-table-column>
        <el-table-column prop="nameServerList" label="nameServerList" align="center" width="150px">
          <template slot-scope="scope">
            <div class="text-theme" style="" v-for="(item, index) in scope.row.nameServerList" :key="index">{{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" width="150px"></el-table-column>
        <el-table-column prop="createByUser" label="创建人" align="center" width="120px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" width="180px"></el-table-column>
        <el-table-column prop="updateByUser" label="更新人" align="center" width="120px"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center" width="180px"> </el-table-column>
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <add-modal :show.sync="showAdd" :detail="curItem" :formType="handleType" @close="closeAdd" />
  </div>
</template>
<script>
import api from '@/api/devops/cluster'
import addModal from './addModal'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  components: { addModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      showAdd: false,
      handleType: 'add',
      curItem: null,
    }
  },
  computed: {
    appCode() {
      return this.$route.query.appCode
    },
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      this.tableLoading = true
      api
        .getNameServer()
        .then(({ data }) => {
          console.log(data)
          this.tableData = data
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAdd() {
      this.handleType = 'add'
      this.curItem = null
      this.showAdd = true
    },
    handleEdit(row) {
      this.handleType = 'edit'
      this.curItem = _.cloneDeep(row)
      this.showAdd = true
    },
    handleDelete(row) {
      this.$confirm('确认删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const { id } = row
        api
          .removeNameServer({ id })
          .then(() => {
            this.$message.success('操作成功')
            this.query()
          })
      })
    },
    close() {
      this.$emit('update:show', false)
    },
    closeAdd() {
      this.showAdd = false
      this.query()
    },
  },
}
</script>
