<template>
  <el-dialog :title="formType == 'add' ? '新增nameServer' : '编辑nameServer'" width="500px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="名称" prop="nameServerName">
        <el-input v-model="form.nameServerName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="nameServerList">
        <div v-for="(item, index) in form.nameServerList" :key="index">
          <div class="flex mb5">
            <el-input v-model="form.nameServerList[index]" class="mr5" placeholder="请输入" />
            <el-button icon="el-icon-minus" plain type="warning" :disabled="form.nameServerList.length == 1" @click="handleDelList(index)"></el-button>
          </div>
        </div>
        <el-button icon="el-icon-plus" plain type="success" @click="handleAddList"></el-button>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" placeholder="请输入" />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handleSave">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/devops/cluster'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    formType: {
      default: () => 'add',
      type: String,
    },
    detail: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      form: {
        nameServerList:[]
      },
      rules: {
        nameServerName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    }
  },
  computed: {
    appCode() {
      return this.$route.query.appCode
    },
  },
  watch: {
    detail(val) {
      if (this.formType == 'add' && !val) {
        this.form = {
          nameServerList: [],
        }
      } else {
        this.form = _.cloneDeep(val)
      }
      this.reset()
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    reset() {
      this.$refs['form'] && this.$refs['form'].resetFields()
    },
    handleSave() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const fn = this.formType == 'add' ? api.addNameServer : api.updateNameServer
          fn(this.form).then(() => {
            this.$message.success('操作成功')
            this.close()
          })
        }
      })
    },
    handleAddList() {
      this.form.nameServerList.push('')
    },
    handleDelList(index) {
      this.form.nameServerList.splice(index, 1)
    },
  },
}
</script>
