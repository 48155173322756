<template>
  <div class="h-full overflow-auto">
    <el-card shadow="never">
      <div slot="header">{{ title }}</div>
      <el-form :model="form" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="集群名称" prop="clusterName">
          <el-input :disabled="dis" type="text" v-model="form.clusterName" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="集群所属区域" prop="regionId">
          <el-input :disabled="dis" type="text" v-model="form.regionId" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="nameServerList" prop="nameserverId">
          <el-select :disabled="dis" v-model="form.nameserverId" style="width: 100%">
            <el-option v-for="(item, index) in nameServerList" :key="index" :label="item.nameServerName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <div class="flex" v-for="(item, index) in form.brokers" :key="index">
          <div class="flex-1">
            <el-form-item label="brokers">
              <el-input class="mr10" v-model="form.brokers[index]" :disabled="dis" placeholder="请输入"> </el-input>
            </el-form-item>
            <el-form-item label="role">
              <el-select class="mr10" v-model="form.role[index]" :disabled="dis" style="width: 100%">
                <el-option label="master" value="master"></el-option>
                <el-option label="slave" value="slave"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="ml5">
            <el-button :disabled="dis || form.brokers.length == 1" @click.prevent="removebroker(index)" icon="el-icon-delete" type="warning" plain></el-button>
          </div>
        </div>
        <el-form-item>
          <el-button :disabled="dis" type="success" plain @click.prevent="addbroker" icon="el-icon-plus"></el-button>
        </el-form-item>
        <el-form-item label="adminAccessKey" prop="adminAccessKey">
          <el-input :disabled="dis" type="text" v-model="form.adminAccessKey" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="adminSecretKey" prop="adminSecretKey">
          <el-input :disabled="dis" type="text" v-model="form.adminSecretKey" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="prometheus Api" prop="prometheusApiAddress">
          <el-input :disabled="dis" type="text" v-model="form.prometheusApiAddress" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="集群类型" prop="type" required>
          <el-select disabled v-model="form.type" style="width: 100%">
            <el-option v-for="item in clusterTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户" prop="tenantCode">
          <el-select :disabled="dis" v-model="form.tenantCode" style="width: 100%" filterable remote :remote-method="remoteMethod" :loading="loadingTentant">
            <el-option v-for="item in tenantList" :key="item.tenantCode" :label="item.tenantCode" :value="item.tenantCode"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :disabled="type == 'detail'" v-model="form.remark" :rows="4" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item v-if="type != 'detail'">
          <el-button :loading="loading" type="primary" @click="submitForm">保存</el-button>
          <el-button @click="reset" :disabled="dis">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import api from '@/api/devops/cluster'
import accountApi from '@/api/operate/account'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    env: {
      type: String,
      default: () => 'dev',
    },
    detail: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    title() {
      return {
        add: '新增集群',
        edit: '编辑集群',
        detail: '集群详情',
      }[this.type]
    },
    dis() {
      return this.type == 'edit' || this.type == 'detail'
    },
  },
  watch: {
    detail: {
      handler() {
        if (this.type === 'add') {
          this.form = {
            brokers: [''],
            role: [''],
            type: 'normal',
          }
        } else {
          this.form = _.cloneDeep(this.detail)
          if (!this.form.brokers) {
            this.form.brokers = ['']
          }
          if (!this.form.role) {
            this.form.role = ['']
          }
        }
        this.$refs['ruleForm']?.resetFields()
      },
      immediate: true,
    },
  },
  data() {
    return {
      clusterTypes: [
        { label: '消息轨迹', value: 'trace' },
        { label: '正常消息', value: 'normal' },
      ],
      form: {
        type: 'normal',
        brokers: [''],
        role: [''],
      },
      rules: {
        clusterName: [{ required: true, message: '请输入集群名称', trigger: 'blur' }],
        regionId: [{ required: true, message: '请输入集群所属区域', trigger: 'blur' }],
        adminAccessKey: [{ required: true, message: '请输入adminAccessKey', trigger: 'blur' }],
        adminSecretKey: [{ required: true, message: '请输入adminSecretKey', trigger: 'blur' }],
        prometheusApiAddress: [{ required: true, message: 'prometheus Api', trigger: 'blur' }],
        type: [{ required: true, message: '请选择集群类型', trigger: 'change' }],
        nameserverId: [{ required: true, message: '请选择nameServer', trigger: 'change' }],
      },
      loading: false,
      nameServerList: [],
      tenantList: [],
      loadingTentant: false,
    }
  },
  created() {
    this.getNameServer()
    this.getTenantList()
  },
  methods: {
    addbroker() {
      this.form.brokers.push('')
      this.form.role.push('')
    },
    removebroker(index) {
      this.form.brokers.splice(index, 1)
      this.form.role.splice(index, 1)
    },
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const nameServerItem = this.nameServerList.find((i) => i.id == this.form.nameserverId)
          const nameServerList = nameServerItem ? nameServerItem.nameServerList : []
          const params = {
            nameServerList,
            env: this.env,
            ...this.form,
          }
          if (params.brokers.some((i) => !i)) {
            return this.$message.warning('brokers不能为空')
          }
          if (params.role.some((i) => !i)) {
            return this.$message.warning('role不能为空')
          }
          const fn = this.type === 'add' ? api.add : api.update
          this.loading = true
          fn(params)
            .then(() => {
              this.$message.success('操作成功')
              this.$emit('close')
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    reset() {
      this.$refs['ruleForm'].resetFields()
    },
    getNameServer() {
      api.getNameServer().then(({ data }) => {
        this.nameServerList = data
      })
    },
    remoteMethod(val) {
      this.getTenantList(val)
    },
    getTenantList(tenantCode) {
      const params = {
        current: 1,
        size: 20,
        tenantCode: tenantCode || this.form.tenantCode,
      }
      this.loadingTentant = true
      accountApi
        .query(params)
        .then(({ data }) => {
          this.tenantList = data.records
        })
        .finally(() => {
          this.loadingTentant = false
        })
    },
  },
}
</script>
