import http from '@/utils/axios'
const api = {
  getList: (data) => http.post(`/rocketmq/cluster/query/page`, data),
  remove: ({ id }) => http.delete(`/rocketmq/cluster/cluster/${id}`),
  add: (data) => http.post(`/rocketmq/cluster/add`, data),
  update: (data) => http.put(`/rocketmq/cluster/update`, data),
  getUnBindList: (data) => http.get(`/rocketmq/instance/query/all_un_assigned`, data),
  bindCluster: (data) => http.post(`/rocketmq/instance/bindCluster`, data),
  getNameServer: (data) => http.get(`/rocketmq/nameServer/query/all`, data),
  addNameServer: (data) => http.post(`/rocketmq/nameServer/add`, data),
  updateNameServer: (data) => http.put(`/rocketmq/nameServer/update`, data),
  removeNameServer: ({ id }) => http.delete(`/rocketmq/nameServer/cluster/${id}`),
  getGroupByNameServer: (data) => http.get(`/rocketmq/cluster/getGroupByNameServer`, data),
}
export default api
